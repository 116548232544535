import Axios from "axios";
import Swal from "sweetalert2";
import {
  convertBase64,
  dataURLtoFile,
  getUserdata,
  localStoragedecryp,
  resizeFile,
} from "../components/helpers/function";
import { ToastNotification } from "../components/helpers/notification";
import firebase from "../firebase";
import { server } from "../components/helpers/library";

// 192.168.1.22
// 188.166.254.162
let timeout_getData = "";
let timeout_getData2 = "";
let timeout_postData = "";
let timeout_postData2 = "";
let timeout_putData = "";
let timeout_putData2 = "";
let timeout_deleteData = "";

export function postImage(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${toko}/${name}.jpg`);
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}
export function deleteImage(name) {
  const toko = getUserdata().kode_toko;

  return new Promise((resolve, reject) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let desertRef = storageRef.child(`NSIPIC/${toko}/${name}.jpg`);
    desertRef
      .delete()
      .then(function () {})
      .catch(function (error) {
        reject(error);
      });
  });
}

// export function getDataParams(endpoint, params) {

//   const isPusat =
//     ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
//   let config = {
//     headers: {
//       "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token
//     },
//     params: params
//   };
//   const portal1 = getItem("portal1");
//   return new Promise((resolve, reject) => {
//     Axios.get(
//       portal1.length > 0 ? portal1 + endpoint : server + endpoint,
//       config
//     )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         if (Axios.isCancel(err)) {
//           reject(err);
//         } else {
//           var hasil = /Invalid Token./i.test(err?.response?.data);
//           if (hasil) {
//             window.location.reload();
//             localStorage.clear();
//             localStorage.setItem("islogin", "false");
//           } else {
//             reject(err);
//           }
//         }
//       });
//   });
// }

export function getImage(file) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${toko}/${file}.jpg`);
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        // console.log('ini url', url)
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = async function (event) {
          let data = await convertBase64(xhr.response);
          const file = dataURLtoFile(data);
          const res = await resizeFile(file);
          resolve(res);
        };
        xhr.onerror = async function (err) {
          // console.log('ERROR',err);
          reject("eror");
        };
        xhr.open("GET", url);
        xhr.send();
        // resolve(url);
      })
      .catch((err) => {
        // console.log(JSON.parse(err.customData.serverResponse).error);
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}
export function getDataNoTimeOut(endpoint) {
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}

export function getEnpointGrosir(endpoint) {
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.get("https://147.139.193.169:3759/api/v1/" + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}
export function postDataGrosir(endpoint) {
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.post("https://147.139.193.169:3759/api/v1/" + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}

export function getDataNoTimeOut2(endpoint) {
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}
export function getData(endpoint) {
  clearTimeout(timeout_getData);
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };
  return new Promise((resolve, reject) => {
    timeout_getData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData);
            reject(err);
          }
        }
      });
  });
}
export function getDataParams(endpoint, params) {
  clearTimeout(timeout_getData2);
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
    params: params,
  };
  return new Promise((resolve, reject) => {
    timeout_getData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData2);
            reject(err);
          }
        }
      });
  });
}
export function getData2(endpoint) {
  clearTimeout(timeout_getData2);
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };
  return new Promise((resolve, reject) => {
    timeout_getData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData2);
            reject(err);
          }
        }
      });
  });
}
export function postData2(endpoint, data) {
  clearTimeout(timeout_postData2);
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_postData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_postData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_postData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_postData2);
            reject(err);
          }
        }
      });
  });
}

export function putData2(endpoint, data) {
  clearTimeout(timeout_putData2);
  // localStorage.setItem("daaa", JSON.stringify(data));
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_putData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.put(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_putData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_putData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_putData2);
            reject(err);
          }
        }
      });
  });
}

export function postDataNoTImeOut(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };

  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function postDataNoTImeOut2(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };

  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function putDataNoTImeOut(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
  };

  return new Promise((resolve, reject) => {
    Axios.put(server + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postData(endpoint, data) {
  clearTimeout(timeout_postData);
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_postData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_postData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_postData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_postData);
            reject(err);
          }
        }
      });
  });
}

export function deleteData(endpoint, data) {
  clearTimeout(timeout_deleteData);
  let hasil = {
    data: data,
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_deleteData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.delete(server + endpoint, hasil)
      .then((res) => {
        clearTimeout(timeout_deleteData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_deleteData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_deleteData);
            reject(err);
          }
        }
      });
  });
}
export function putData(endpoint, data) {
  clearTimeout(timeout_putData);
  // localStorage.setItem("daaa", JSON.stringify(data));
  let config = {
    headers: {
      "x-auth-token": localStoragedecryp("token"),
      "ngrok-skip-browser-warning": 1,
    },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_putData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Tidak Terhubung Keserver",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.put(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_putData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_putData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_putData);
            reject(err);
          }
        }
      });
  });
}

export function checkTimeout(err) {
  if (new RegExp("timeout", "g").test(err)) {
    ToastNotification("info", "Koneksi bermasalah..");
    return false;
  } else {
    return true;
  }
}
