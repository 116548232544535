import React, {
  Component,
  useState,
  useEffect,
  lazy,
  Suspense,
  useContext
} from "react";
import Resizer from "react-image-file-resizer";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelContent
} from "../panel/panel.jsx";
import {
  hideModal,
  isLoading,
  showModal,
  isEdit,
  simpanDataTmp
} from "../../actions/datamaster_action";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { Modal, ModalBody, Progress } from "reactstrap";
import Swal from "sweetalert2";
import { NotificationConfirm, ToastNotification } from "./notification.jsx";
import { reset } from "redux-form";
import {
  deleteData,
  getDataNoTimeOut2,
  postData,
  putDataNoTImeOut,
  putData,
  getData2,
  postData2,
  getData,
  getDataNoTimeOut,
  postDataNoTImeOut,
  postDataNoTImeOut2,
  getDataParams
} from "../../config/axios.jsx";
import Tabel from "./tabel.jsx";
import ModalGlobal from "./ModalGlobal.jsx";
import {
  ReanderField,
  HiidenFiled,
  ReanderSelect,
  SkeletonLoading,
  InputDate,
  ReanderCheckBox
} from "./field";
import { v4 as uuidv4 } from "uuid";
import {
  ErrorHandling,
  convertDate,
  convertBase64,
  getItem,
  setItem,
  localStoragedecryp,
  localStorageencryp,
  formatDate,
  getTglSystem,
  getToday,
  getUserdata,
  setTitle,
  capitalize
} from "./function.jsx";
import { Gramasi, NumberOnly } from "../../Validasi/normalize";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import imgfound from "../../assets/img/temp.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import Camera from "react-html5-camera-photo";
import * as moment from "moment-timezone";
import { createNumberMask } from "redux-form-input-masks";
import DualListBox from "react-dual-listbox";
import Loading from "react-fullscreen-loading";
import * as XLSX from "xlsx";
import { ListTokoKresno } from "./ListDataToko.jsx";
import { selectorMaster } from "../../selector";
import FilterLaporanTgl from "./FilterLaporanTgl.jsx";
import { useSelector } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { io } from "socket.io-client";
import routes from "../../config/page-route.jsx";
// import { doEncrypt } from "./encrypt.js";
import bgputih from "../../assets/img/bgputih.jpg";
import firebase from "../../firebase.js";
import { doDecrypt } from "./encrypt.js";

let globalConfigEnv = "";
let globalConfig = "";
if (process.env.REACT_APP_IS_ENCRYPT === "true") {
  globalConfigEnv = doDecrypt(window.globalConfig);
  globalConfig = JSON?.parse(globalConfigEnv?.replaceAll("~", ""));
} else {
  // globalConfigEnv = window.globalConfig;
  globalConfig = window.globalConfig;
}

const server = globalConfig.REACT_APP_BACKEND_URL;
const url = globalConfig.REACT_APP_URL;
const isPusat = globalConfig.REACT_APP_TYPE === "PUSAT" ? true : false;
const serverWa = globalConfig.REACT_APP_BACKEND_WA;
const serverGrosir = globalConfig.REACT_APP_BACKEND_SERVER_GROSIR;
const serverOnlineStore = globalConfig.REACT_APP_BACKEND_ONLINE_STORE;
const firebasemame = globalConfig.REACT_APP_FIREBASE_NAME;
const ModePusat = globalConfig.REACT_APP_TYPE;
const appVersion = globalConfig.REACT_APP_VERSION;
const isencrytENv = globalConfig.REACT_APP_IS_ENCRYPT;
const AuthManual = globalConfig.REACT_APP_AUTH_TYPE;
const DomainGrosir = globalConfig.REACT_APP_DOMAIN_GROSIR;
const kodeTokoPusat = globalConfig.REACT_APP_KODE_TOKO_PST || "PST";
const AppMode = globalConfig.REACT_APP_TYPE;

export const dataURLtoFile = (dataurl, filename) => {
  // export const dataURLtoFile = (dataurl, filename) =>
  let arr = dataurl.split(","),
    // mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename + ".jpg", { type: "image/jpg" });
};

export const isPos = () => {
  const version = "WEB";
  const ua = navigator.userAgent;

  if (
    version.includes("POS") ||
    (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) &&
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      ))
  ) {
    return true;
  } else {
    return false;
  }
};

export const resizeFileCustom = (file, width, height) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      50,
      0,
      uri => {
        resolve(uri);
      },
      "base64"
    );
  });

export const NotifPlaform = async () =>
  await Swal.fire({
    title: "Pilih Nota Platform",
    input: "select",
    inputOptions: {
      false: "Desktop",
      true: "Mobile"
    },
    // inputPlaceholder: 'Pilih Platform',
    showCancelButton: true,
    inputValidator: value => {
      return new Promise(resolve => {
        if (value) {
          resolve();
        } else {
          resolve("You need to select oranges :)");
        }
      });
    }
  });

export const findModule = name => {
  return getItem("module").findIndex(cek => cek.key === name) === -1
    ? false
    : true;
};
export const findModuleText = name => {
  return getItem("module").find(cek => cek.key === name);
};

export const removeItem = nama => {
  localStorage.removeItem(nama);
};
export function postImage(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${firebasemame || toko}/${name}.jpg`);
    stoageRef
      .put(file)
      .then(res => {
        stoageRef.getDownloadURL().then(function(url) {
          resolve(url);
        });
      })
      .catch(err => {
        reject(JSON.parse(err));
      });
  });
}
export {
  NotificationConfirm,
  change,
  capitalize,
  url,
  setTitle,
  server,
  routes,
  io,
  React,
  Route,
  withRouter,
  XLSX,
  ListTokoKresno,
  convertDate,
  getToday,
  lazy,
  bgputih,
  Gramasi,
  Loading,
  PanelBody,
  putDataNoTImeOut,
  Suspense,
  useContext,
  getDataNoTimeOut,
  getDataNoTimeOut2,
  postDataNoTImeOut,
  postDataNoTImeOut2,
  getData2,
  postData2,
  DualListBox,
  formValueSelector,
  ReanderCheckBox,
  NumberOnly,
  localStorageencryp,
  InputDate,
  getTglSystem,
  localStoragedecryp,
  Modal,
  ModalBody,
  Progress,
  imgfound,
  formatDate,
  moment,
  createNumberMask,
  Camera,
  convertBase64,
  ToolkitProvider,
  SkeletonLoading,
  Skeleton,
  BootstrapTable,
  useState,
  useEffect,
  ErrorHandling,
  uuidv4,
  setItem,
  getItem,
  isLoading,
  showModal,
  isEdit,
  ToastNotification,
  Swal,
  reset,
  Link,
  PanelHeader,
  Component,
  Panel,
  ModalGlobal,
  Tabel,
  connect,
  Field,
  hideModal,
  reduxForm,
  getData,
  HiidenFiled,
  ReanderField,
  ReanderSelect,
  deleteData,
  postData,
  putData,
  selectorMaster,
  FilterLaporanTgl,
  getDataParams,
  simpanDataTmp,
  PanelContent,
  useDispatch,
  useSelector,
  getUserdata,
  isPusat,
  serverWa,
  serverGrosir,
  serverOnlineStore,
  ModePusat,
  appVersion,
  isencrytENv,
  AuthManual,
  DomainGrosir,
  kodeTokoPusat,
  AppMode
};
