import { getUserdata } from "../../components/helpers/function";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  Field,
  ReanderSelect,
  ToastNotification,
  XLSX,
  postDataNoTImeOut,
  convertDate,
  Loading,
  ListTokoKresno,
} from "../../components/helpers/library";

const data = [
  {
    value: "master_sales",
    name: "Master Sales",
  },
  {
    value: "master_group",
    name: "Master Group",
  },
  {
    value: "master_jenis",
    name: "Master Jenis",
  },
  {
    value: "master_gudang",
    name: "Master Gudang",
  },
  {
    value: "master_baki",
    name: "Master Baki",
  },
  {
    value: "penjualan",
    name: "Penjualan",
  },
  {
    value: "pembelian",
    name: "Pembelian",
  },
  {
    value: "keuangan_cash",
    name: "Keuangan Cash",
  },
  {
    value: "keuangan_non_cash",
    name: "Keuangan Non Cash",
  },
  {
    value: "barang_summary",
    name: "Barang Summary",
  },
];
class ImportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "Pilih File Exel",
      file: [],
      type: "",
      dataimport: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit() {}

  componentDidMount() {
    if (getUserdata().kode_toko === "TRA") {
      this.props.change("type", "penjualan");
      this.setState({
        type: "penjualan",
      });
    }
  }
  getValue(e) {
    this.setState({
      name: e?.target?.files?.[0]?.name,
      file: e?.target?.files?.[0],
    });
    this.readFile(e.target.files[0]);
  }
  simpanData() {
    if (this.state.type === "") {
      ToastNotification("info", "Type Belum Di Pilih");
      return false;
    }
    if (this.state.file.length === 0) {
      ToastNotification("info", "File Belum Di Pilih");
      return false;
    }

    this.state.dataimport.pop();
    let kode_toko = ListTokoKresno.filter(
      (cek) => cek.kode_toko === getUserdata().kode_toko
    );

    this.setState({
      isLoading: true,
    });
    let url = "";
    let feedback = [];
    if (this.state.type === "master_sales") {
      if (/master_sales/i.test(this.state.name)) {
        let data = [];
        url = "truncate/sales";
        this.state.dataimport.forEach((list) => {
          let row = {
            kode_sales: list.kode_sales || "-",
            nama_sales: list.sales_name || "-",
          };
          data.push(row);
        });
        feedback = {
          data_sales: data,
        };
      }
    }else if (this.state.type === "master_group") {
      if (/master_group/i.test(this.state.name)) {
        let data = [];
        url = "group/truncate";
        this.state.dataimport.forEach((list) => {
          let row = {
            kode_group: list.kode_group || "-",
            nama_group: list.nama_group || "-",
            jenis_group: list.jenis_group || "-",
            harga: parseInt(list.harga) || 0,
            harga_modal: parseInt(list.harga_modal) || 0,
            persentase: parseInt(0),
            status_aktif: true,
          };
          data.push(row);
        });
        feedback = {
          data_groups: data,
        };
      } else {
        ToastNotification("info", "Data Di Import Bukan Group");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "master_jenis") {
      if (/master_jenis/i.test(this.state.name)) {
        let data = [];
        url = "jenis/truncate";
        this.state.dataimport.forEach((list) => {
          let row = {
            kode_dept: list.kode_jenis || "-",
            nama_dept: list.nama_jenis || "-",
            kode_group: list.kode_group || "-",
            status_aktif: true,
          };
          data.push(row);
        });
        feedback = {
          data_jenises: data,
        };
      } else {
        ToastNotification("info", "Data Di Import Bukan Jenis");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "master_gudang") {
      if (/master_gudang/i.test(this.state.name)) {
        let data = [];
        url = "gudang/truncate";
        this.state.dataimport.forEach((list) => {
          let row = {
            kode_gudang: list.kode_gudang || "-",
            nama_gudang: list.nama_gudang || "-",
            status_aktif: true,
          };
          data.push(row);
        });
        feedback = {
          data_gudangs: data,
        };
      } else {
        ToastNotification("info", "Data Di Import Bukan Gudang");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "master_baki") {
      if (/master_baki/i.test(this.state.name)) {
        let data = [];
        url = "baki/truncate";
        this.state.dataimport.forEach((list) => {
          let row = {
            kode_baki: list.kode_baki || "-",
            nama_baki: list.nama_baki || "-",
            kode_gudang: list.kode_gudang || "-",
            berat_bandrol: parseFloat(list.berat_bandrol) || 0,
            berat_baki: parseFloat(list.berat_baki) || 0,
            status_aktif: true,
          };
          data.push(row);
        });
        feedback = {
          data_bakis: data,
        };
      } else {
        ToastNotification("info", "Data Di Import Bukan Gudang");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "penjualan") {
      if (/penjualan/i.test(this.state.name)) {
        let data = [];
        url = "truncate/penjualan";
        this.state.dataimport.forEach((el) => {
          if (kode_toko === 1) {
            let row = {
              no_generate: el.no_generate || "-",
              no_faktur_group: el.no_fakturgroup || "-",
              no_faktur_group_user: el.no_fakturgroupuser || "-",
              no_faktur_jual: el.no_fakturjual || "-",
              tgl_system: convertDate(el.tgl_system) || "-",
              kode_sales: el.kode_sales || "-",
              kode_member: el.kode_member || "-",
              nama_customer: el.nama_customer || "-",
              no_hp: el.no_hp || "-",
              nama_sales: el.nama_sales || "-",
              kadar_cetak: el.kadar_cetak || "-",
              alamat_customer: el.alamat_customer || "-",
              kode_gudang: el.kode_gudang || "-",
              kode_baki: el.kode_baki || "-",
              kode_barcode: String(el.kode_barcode).padStart("8", "0") || "-",
              kode_barang: el.kode_barang || "-",
              kode_group: el.kode_group || "-",
              kode_dept: el.kode_dept || "-",
              kode_marketplace: el.kode_marketplace || "-",
              nama_barang: el.nama_barang || "-",
              berat_awal: parseFloat(el.berat_awal) || 0,
              berat: parseFloat(el.berat) || 0,
              nama_atribut: el.nama_atribut || "-",
              berat_atribut: parseFloat(el.berat_atribut) || 0,
              harga_jual: parseInt(el.harga_jual) || 0,
              harga_gram: parseInt(el.harga_gram) || 0,
              harga_atribut: parseInt(el.harga_atribut) || 0,
              ongkos: parseInt(el.ongkos),
              harga_total: parseInt(el.harga_total),
              input_by: el.input_by || "-",
              status_kembali: el.status_kembali || "-",
              status_valid: el.status_valid || "-",
              status_premi: el.status_premi || "-",
              status_tukar: el.status_tukar === "false" ? false : true || false,
              no_titip_group: el.no_titipgroup || "-",
              no_pesanan: el.no_pesanan || "-",
              jam: el.jam || "-",
              cash_rp: parseInt(el.cash_rp) || 0,
              transfer_rp: parseInt(el.transfer_rp) || 0,
              debet_rp: parseInt(el.debet_rp) || 0,
              tukar_rp: parseInt(el.tukar_rp) || 0,
              credit_rp: parseInt(el.credit_rp),
              total_usd: parseInt(el.total_usd || 0),
              curr: parseInt(el.curr || 0),
              total_berlian_rp: parseInt(el.total_berlianrp || 0),
              // kode_intern: el.kode_intern || "-"
            };
            data.push(row);
          } else {
            let row = {
              no_generate: el.no_generate || "-",
              no_faktur_group: el.no_fakturgroup || "-",
              no_faktur_group_user: el.no_fakturgroupuser || "-",
              no_faktur_jual: el.no_fakturjual || "-",
              tgl_system: convertDate(el.tgl_system) || "-",
              kode_sales: el.kode_sales || "-",
              kode_member: el.kode_member || "-",
              nama_customer: el.nama_customer || "-",
              no_hp: el.no_hp || "-",
              nama_sales: el.nama_sales || "-",
              kadar_cetak: el.kadar_cetak || "-",
              alamat_customer: el.alamat_customer || "-",
              kode_gudang: el.kode_gudang || "-",
              kode_baki: el.kode_baki || "-",
              kode_barcode: String(el.kode_barcode).padStart("8", "0") || "-",
              kode_barang: el.kode_barang || "-",
              kode_group: el.kode_group || "-",
              kode_dept: el.kode_dept || "-",
              kode_marketplace: el.kode_marketplace || "-",
              nama_barang: el.nama_barang || "-",
              berat_awal: parseFloat(el.berat_awal) || 0,
              berat: parseFloat(el.berat) || 0,
              nama_atribut: el.nama_atribut || "-",
              berat_atribut: parseFloat(el.berat_atribut) || 0,
              harga_jual: parseInt(el.harga_jual) || 0,
              harga_gram: parseInt(el.harga_gram) || 0,
              harga_atribut: parseInt(el.harga_atribut) || 0,
              ongkos: parseInt(el.ongkos),
              harga_total: parseInt(el.harga_total),
              input_by: el.input_by || "-",
              status_kembali: "OPEN",
              status_valid: "DONE",
              status_premi: el.status_premi || "-",
              status_tukar: el.status_tukar === "false" ? false : true || false,
              no_titip_group: el.no_titipgroup || "-",
              no_pesanan: el.no_pesanan || "-",
              jam: el.jam || "-",
              cash_rp: parseInt(el.cash_rp) || 0,
              transfer_rp: parseInt(el.transfer_rp) || 0,
              debet_rp: parseInt(el.debet_rp) || 0,
              tukar_rp: parseInt(el.tukar_rp) || 0,
              credit_rp: parseInt(el.credit_rp),
              total_usd: parseInt(el.total_usd || 0),
              curr: parseInt(el.curr || 0),
              total_berlian_rp: parseInt(el.total_berlianrp || 0),
              // kode_intern: el.kode_intern || "-"
            };
            data.push(row);
          }
        });

        feedback = {
          data_penjualans: data,
        };
      } else {
        ToastNotification("info", "Data Di Import Bukan Gudang");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "pembelian") {
      if (/pembelian/i.test(this.state.name)) {
        let data = [];
        url = "pembelian/truncate";
        this.state.dataimport.forEach((el) => {
          if (kode_toko === 1) {
            let berat_nota = 0;
            if (el.berat_nota === null) {
              berat_nota = 0;
            } else {
              berat_nota = el.berat_nota;
            }
            let row = {
              no_faktur_group: el.no_fakturgroup || "-",
              no_faktur_beli: el.no_fakturbeli || "-",
              tgl_system: convertDate(el.tgl_system),
              kode_sales: el.kode_sales || "-",
              kode_member: el.kode_member || "-",
              nama_customer: el.nama_customer || "-",
              no_hp: el.no_hp || "0",
              alamat_customer: el.alamat_customer || "-",
              kode_gudang: el.kode_gudang || "-",
              no_faktur_ext: el.no_fakturext || "-",
              kode_group: el.kode_group || "-",
              kode_dept: el.kode_dept || "-",
              kode_barcode:
                el.kode_barcode === "-"
                  ? "-"
                  : String(el.kode_barcode).padStart("8", "0") || "-",
              nama_barang: el.nama_barang || "-",
              berat_nota: Number(berat_nota) || 0,
              berat: Number(el.berat || 0),
              harga_nota: parseInt(el.harga_nota || 0),
              harga: parseInt(el.harga || 0),
              harga_rata: parseInt(el.harga_rata || 0),
              kondisi: el.kondisi || "-",
              // cash_rp: parseInt(el.cash_rp || 0),
              input_by: el.input_by || "-",
              status_valid: el.status_valid || false,
              type_kondisi: el.type_kondisi,
            };
            data.push(row);
          } else {
            let berat_nota = 0;
            if (el.berat_nota === null) {
              berat_nota = 0;
            } else {
              berat_nota = el.berat_nota;
            }
            let row = {
              no_faktur_group: el.no_fakturgroup || "-",
              no_faktur_beli: el.no_fakturbeli || "-",
              tgl_system: convertDate(el.tgl_system),
              kode_sales: el.kode_sales || "-",
              kode_member: el?.kode_member || "-",
              nama_customer: el?.nama_customer || "-",
              no_hp: el.no_hp || "0",
              alamat_customer: el?.alamat_customer || "-",
              kode_gudang: el?.kode_gudang || "-",
              no_faktur_ext: el?.no_fakturext || "-",
              kode_group: el?.kode_group || "-",
              kode_dept: el?.kode_dept || "-",
              kode_barcode:
                el.kode_barcode === "-"
                  ? "-"
                  : String(el.kode_barcode).padStart("8", "0") || "-",
              nama_barang: el.nama_barang || "-",
              berat_nota: Number(berat_nota) || 0,
              berat: Number(el?.berat || 0) || 0,
              kadar: Number(el?.kadar || 0) || 0,
              cash_rp: Number(el?.cash_rp || 0) || 0,
              harga_nota: parseInt(el?.harga_nota || 0) || 0,
              harga: parseInt(el?.harga || 0) || 0,
              harga_rata: parseInt(el?.harga_rata || 0) || 0,
              kondisi: el.kondisi || "-",
              // cash_rp: parseInt(el.cash_rp || 0),
              input_by: el.input_by || "-",
              status_valid: "DONE",
              type_kondisi: "-",
            };
            data.push(row);
          }
        });
        let datasorted = data.sort((a, b) => {
          if (a.no_faktur_jual < b.no_faktur_jual) {
            return -1;
          } else {
            return 1;
          }
        });
        feedback = {
          data_pembelians: datasorted,
        };
        console.log(feedback);
      } else {
        ToastNotification("info", "Data Di Import Bukan Gudang");
        this.setState({
          isLoading: false,
        });
        return false;
      }
    } else if (this.state.type === "keuangan_cash") {
      console.log(this.state.type, "MASUK_TYPE")
      let data = [];
      url = "cash/import-data-cash";
      this.state.dataimport.forEach((list) => {
        let row = {
          no_rekening: list.no_rekening || "-",
          fee: Number(list.fee || 0),
          ppn_rp: Number(list.ppn_rp || 0),
          no_reff: list.no_reff || "-",
          tanggal: list.tanggal,
          jenis: list.jenis,
          deskripsi: list.deskripsi || "-",
          keterangan: list.keterangan || "-",
          kategori: list.kategori || "-",
          jumlah_in: Number(list.jumlah_in || 0),
          jumlah_out: Number(list.jumlah_out || 0),
          status: list.status,
          input_by: list.input_by,
          input_date: list.input_date,
          input_by_cashier: list.input_by_cashier,
          valid_by: list.valid_by,
        };
        data.push(row);
      });
      feedback = {
        data: data,
      };
      console.log(feedback, "DATA_FEEDBACK")
    } else if (this.state.type === "keuangan_non_cash") {
      let data = [];
      url = "cash/import-data-cash";
      this.state.dataimport.forEach((list) => {
        let row = {
          no_rekening: list.no_rekening || "-",
          fee: Number(list.fee || 0),
          ppn_rp: Number(list.ppn_rp || 0),
          no_reff: list.no_reff || "-",
          tanggal: list.tanggal,
          jenis: list.jenis,
          deskripsi: list.deskripsi || "-",
          keterangan: list.keterangan || "-",
          kategori: list.kategori || "-",
          jumlah_in: Number(list.jumlah_in || 0),
          jumlah_out: Number(list.jumlah_out || 0),
          status: list.status,
          input_by: list.input_by,
          input_date: list.input_date,
          input_by_cashier: list.input_by_cashier,
          valid_by: list.valid_by,
        };
        data.push(row);
      });
      feedback = {
        data: data,
      };
    } else if (this.state.type === "barang_summary") {
      let data = [];
      url = "cash/import-barang-summary";
      this.state.dataimport.forEach((list) => {
        let row = {
          tanggal: list.tanggal || "-",
          kode_dept: list.kode_dept || "-",
          kode_group: list.kode_group || "-",
          kode_gudang: list.kode_gudang || "-",
          stock_awal: list.stock_awal || 0,
          berat_awal: list.berat_awal || 0,
          stock_in: list.stock_in || 0,
          berat_in: list.berat_in || 0,
          stock_out: list.stock_out || 0,
          berat_out: list.berat_out || 0,
          stock_tambah: list.stock_tambah || 0,
          berat_tambah: list.berat_tambah || 0,
          stock_hancur: list.stock_hancur || 0,
          berat_hancur: list.berat_hancur || 0,
          stock_jual: list.stock_jual || 0,
          berat_jual: list.berat_jual || 0,
          stock_beli: list.stock_beli || 0,
          berat_beli: list.berat_beli || 0,
          stock_akhir: list.stock_akhir || 0,
          berat_akhir: list.berat_akhir || 0,
        }
        data.push(row);
      });
      feedback = {
        data: data,
      }
    } else {
      ToastNotification("info", "Data Gagal Di Import");
      this.setState({
        isLoading: false,
      });
      return false;
    }

    postDataNoTImeOut(url, feedback)
      .then((res) => {
        ToastNotification("success", "Data Berhasil Di import");
        this.setState({
          name: "Pilih File Exel",
          file: [],
          type: "",
          dataimport: [],
          isLoading: false,
        });
        this.props.change("type", "");
      })
      .catch((error) => {
        ToastNotification(
          "info",
          error?.response?.data || "Terjadi Kesalahan Saat Import Data"
        );
        this.setState({
          isLoading: false,
        });
      });
  }
  readFile(file) {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      let hasil = JSON.parse(this.convertToJson(data));
      this.setState({
        dataimport: hasil,
      });
    };
    reader.readAsBinaryString(file);
  }
  convertToJson(csv) {
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].toLowerCase().replace(" ", "_")] = currentline[j];
      }
      result.push(obj);
    }
    return JSON.stringify(result); //JSON
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Import Data</Link>
          </li>
        </ol>
        <h1 className="page-header">import Data </h1>
        <Panel>
          <PanelHeader>import Data</PanelHeader>
          <PanelBody>
            <div className="row">
              <div className="col-4">
                <Field
                  tabIndex="1"
                  id="type"
                  label="Type import"
                  name="type"
                  onChange={(e) => this.setState({ type: e })}
                  options={
                    getUserdata().kode_toko === "TRA"
                      ? data
                          .filter((cek) => cek.value === "penjualan")
                          .map((hasil) => {
                            let row = {
                              name: hasil.name,
                              value: hasil.value,
                            };
                            return row;
                          })
                      : data.map((hasil) => {
                          let row = {
                            name: hasil.name,
                            value: hasil.value,
                          };
                          return row;
                        })
                  }
                  placeholder={"Silahkan Pilih Type Import"}
                  component={ReanderSelect}
                />
              </div>
              <div className="col-4 mt-4">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      accept=".csv"
                      type="file"
                      name="file"
                      autoComplete="off"
                      className="custom-file-input"
                      onChange={(e) => this.getValue(e)}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="exampleInputFile"
                    >
                      {this.state.name}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-4 mt-4">
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  onClick={() => this.simpanData()}
                  disabled={this.state.isLoading}
                >
                  Import Data
                </button>
              </div>
            </div>
            {this.state.isLoading ? (
              <Loading
                loading
                background="rgba(0, 0, 0, 0.35)"
                loaderColor="rgba(94, 147, 117, 1)"
              />
            ) : null}
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

ImportData = reduxForm({
  form: "ImportData",
  enableReinitialize: true,
})(ImportData);
export default connect()(ImportData);
